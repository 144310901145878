<template>
    <v-sheet
      class="mb-4 pa-3 pb-4 text-center outlet-form sheet-form"
    >
      <h3 class="mb-4">Choices</h3>
      <v-btn block color="primary" :loading="busy" @click="submit()">CLEAR CHOICES HISTORY</v-btn>
    </v-sheet>
</template>

<script>
import data from './../mixins/data'

export default {
    mixins: [data],
    data: () => ({
        busy: false
    }),
    methods: {
        submit() {
            this.postData(`/cfg/bets/clear`, {}, () => {
                this.$store.dispatch("prompt", { message: 'Choices history cleared!' });
            })
        }
    }
}
</script>